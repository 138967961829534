export const isAdobeTagEnabled = () =>
  import.meta.env.VITE_ADOBE_TAG_ENABLED === "true";

export const initAdobeTag = () => {
  const hasScriptAlready = Boolean(
    Array.from(document.getElementsByTagName("script")).find((script) =>
      script.src.includes(
        "assets.adobedtm.com/78ef23cd3941/9347026afa2d/launch-1901af4e408c.min.js"
      )
    )
  );
  if (!hasScriptAlready && !isAdobeTagEnabled()) {
    const src = `https://assets.adobedtm.com/78ef23cd3941/9347026afa2d/launch-1901af4e408c.min.js`;
    const adobeTagScriptElement = document.createElement("script");
    adobeTagScriptElement.type = "text/javascript";
    adobeTagScriptElement.async = true;
    adobeTagScriptElement.src = src;
    document.getElementsByTagName("head")[0].appendChild(adobeTagScriptElement);
  }
};
