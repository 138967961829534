import { IS_LOCAL, IS_PROD } from "../../config/constants";
import { useScript } from "../../utils/useScript";

export const areCookiesEnabled = () =>
  import.meta.env.VITE_COOKIES_ENABLED === "true";

export const useCookieScript = () => {
  if (IS_LOCAL || !areCookiesEnabled()) return;

  if (IS_PROD) {
    useScript(
      "https://assets.adobedtm.com/78ef23cd3941/9347026afa2d/launch-1901af4e408c.min.js"
    );
  } else {
    useScript(
      "https://assets.adobedtm.com/78ef23cd3941/9347026afa2d/launch-353880831a1f-development.min.js"
    );
  }
  return;
};
