import { useEffect } from "react";

export const useScript = (scriptUrl: string) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = scriptUrl;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [scriptUrl]);
};
